.small {
  min-height: 3.8rem;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

}

.large {
  min-height: 6rem;
  padding: 2.1rem 3.1rem;
  font-size: 2rem;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}