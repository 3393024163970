.consider-content {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3.2rem;
}

.value-heading {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 3.2rem;
}

.value-of-investment-section {
    border-radius: 2rem;
}
