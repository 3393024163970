
.minimized-header-title {
  font-size: 5rem;
  line-height: 5.5rem;
  letter-spacing: 0.01rem;
}


.minimized-header-title {
  padding-left: 9rem;
}
