@tailwind base;
@tailwind components;
@tailwind utilities;
/******************************
          REM BASE
*******************************/

@media (max-width: 599px) {
  html {
    font-size: 8px;
  }
}

@media (min-width: 600px) and (max-width: 1919px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 2560px) and (max-width: 3439px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 3440px) {
  html {
    font-size: 20px;
  }
}

/*****************************/
/*          Colfax          */
/*****************************/
@font-face {
  font-family: "Colfax";
  src: local("Colfax-Thin"),
    url(assets/font-family/colfax/Colfax-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-ThinItalic"),
    url(assets/font-family/colfax/Colfax-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-ExtraLight"),
    url(assets/font-family/colfax/Colfax-Thin.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-ExtraLightItalic"),
    url(assets/font-family/colfax/Colfax-ThinItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-Light"),
    url(assets/font-family/colfax/Colfax-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-LightItalic"),
    url(assets/font-family/colfax/Colfax-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-Regular"),
    url(assets/font-family/colfax/Colfax-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-Italic"),
    url(assets/font-family/colfax/Colfax-RegularItalic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-Medium"),
    url(assets/font-family/colfax/Colfax-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-MediumItalic"),
    url(assets/font-family/colfax/Colfax-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-SemiBold"),
    url(assets/font-family/colfax/Colfax-Medium.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-SemiBoldItalic"),
    url(assets/font-family/colfax/Colfax-MediumItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-Bold"),
    url(assets/font-family/colfax/Colfax-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-BoldItalic"),
    url(assets/font-family/colfax/Colfax-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "Colfax";
  src: local("Colfax-ExtraBold"),
    url(assets/font-family/colfax/Colfax-Bold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-ExtraBoldItalic"),
    url(assets/font-family/colfax/Colfax-BoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-Black"),
    url(assets/font-family/colfax/Colfax-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: local("Colfax-Black"),
    url(assets/font-family/colfax/Colfax-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}


/*****************************/
/*          montserrat          */
/*****************************/
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Thin"),
    url(assets/font-family/montserrat/Montserrat-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ThinItalic"),
    url(assets/font-family/montserrat/Montserrat-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ExtraLight"),
    url(assets/font-family/montserrat/Montserrat-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ExtraLightItalic"),
    url(assets/font-family/montserrat/Montserrat-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Light"),
    url(assets/font-family/montserrat/Montserrat-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-LightItalic"),
    url(assets/font-family/montserrat/Montserrat-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Regular"),
    url(assets/font-family/montserrat/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* @font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Italic"),
    url(assets/font-family/montserrat/) format("truetype");
  font-weight: 400;
  font-style: italic;
} */

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Medium"),
    url(assets/font-family/montserrat/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-MediumItalic"),
    url(assets/font-family/montserrat/Montserrat-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-SemiBold"),
    url(assets/font-family/montserrat/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-SemiBoldItalic"),
    url(assets/font-family/montserrat/Montserrat-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Bold"),
    url(assets/font-family/montserrat/Montserrat-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-BoldItalic"),
    url(assets/font-family/montserrat/Montserrat-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ExtraBold"),
    url(assets/font-family/montserrat/Montserrat-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ExtraBoldItalic"),
    url(assets/font-family/montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Black"),
    url(assets/font-family/montserrat/Montserrat-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Black"),
    url(assets/font-family/montserrat/Montserrat-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

body {
  font-family: "Colfax" !important;
  height: 100%;
  width: 100%;
}

a:hover {
  color: #32BEB7;
}

.definition-clickable:hover {
  color: #32BEB7 !important;
}

.summary-print-button {
  min-height: 4rem;
  padding: 1rem 2rem;
  font-size: 2rem;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.print-review {
  overflow: 'hidden';
  height: 0
}