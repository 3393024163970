.small {
  min-height: 4rem;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.large {
  min-height: 6.2rem;
  padding: 2.1rem 3.1rem;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}