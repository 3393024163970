.dialog-box-heading {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 3.2rem;

}

.dialog-box-content {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 3.2rem;

}