.heading-content {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3.2rem;
}

.expandable-heading {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.2rem;
}

.icon {
    font-size: 4rem;
}

.help-section {
    border-radius: 2rem;
}

.help-heading {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 3.2rem;
}

.help-content {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 3.2rem;
}

.help-hyperlink {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 3.2rem;
}

.start-content {
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 400;
}

.start-hyperlink {
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 800;
    color: #165E97;
}

.video-player {
    border-radius: 2rem;
    border: 4px solid #F3E918;
}

.dashed-line {
    border-top: 1px dashed black;

}

.no-bullet {
    list-style-type: none;
}

.underline-link {
    text-decoration: underline;
}