.list-view-label {
    font-weight: 800;
    font-size: 2.0rem;
    line-height: 2.7rem;
}

.list-view-content{
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.7rem;
}